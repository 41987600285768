import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Headline, PrimaryButton } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import 'swiper/css';
import 'swiper/css/pagination';
import theme from 'utils/theme';
import Layout from 'layouts/Layout';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import quizSvgs from 'utils/constants/quizSvgs';
import {
  getElement,
  getEnergy,
  getZodiacSvgName,
} from 'utils/constants/zodiac';
import AstrologicalProfile from './components/MgAstrologicalProfile';
import CompatibilityProfile from './components/MgCompatibilityProfile';

export interface ChartData {
  title: string;
  value: number;
}

export interface BirthTime {
  hours: string;
  minutes: string;
  ampm: string;
}

export interface UserData {
  dateOfBirth: Date;
  timeOfBirth: BirthTime | null;
  placeOfBirth: string | null;
  gender: string;
  zodiac: string;
  relationshipStatus?: string;
}

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
  color: 'mgSecondary100',
})`
  background: ${({ theme }) => theme.colors.mgSecondary100};

  div {
    font-family: 'Afacad', sans-serif;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: unset !important;
  text-wrap: nowrap !important;
  font-size: 1rem !important;
  padding: 0.5rem 1rem !important;

  div {
    text-wrap: nowrap !important;
    line-height: normal !important;
  }
`;

const MgButton = styled.button`
  display: flex;
  height: 2.25rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  font-family: 'Afacad', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  /* text-transform: capitalize; */
  border-radius: 2.25rem;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.mgSecondary100};
  border: none;
`;

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;

  @media ${tablet} {
    padding: 1.5rem 0 2rem;
  }
`;

const ContinueButton = styled.button`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.mgSecondary100};
  height: 3.5rem;
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  width: 100%;
  min-width: 21.4375rem;
  max-width: 28.75rem;
  font-family: 'Afacad', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const config = useSelector((state: AppState) => state.config);
  const user = useSelector((state: AppState) => state.user);

  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [chartData, setChartData] = useState<ChartData[] | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [partnerData, setPartnerData] = useState<UserData | null>(null);
  const [inRelationship, setInRelationship] = useState<boolean>(false);

  const variant = config?.variant;
  const quizData = useQuizData('results');
  const { goToEmail, goToCheckout } = useRouter();
  const { isTablet } = useQuery();
  const quiz_flow_answers = useSelector(
    (state: AppState) => state.user?.quiz_flow_answers,
  );

  const getQuestionKey = (answersKeys: Set<string>[], sectionsKeys: any) => {
    const temp = new Set(sectionsKeys);

    for (const element of temp) {
      const parts = element.split('_');
      if (answersKeys.every(key => parts.includes(key))) {
        return element;
      }
    }

    return -1;
  };

  const renderSvgImage = (svgKey: keyof typeof quizSvgs) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  useEffect(() => {
    window?.scroll(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    const answers = user.quiz_answers;

    if (answers && quizData) {
      const newUserData: UserData = {
        dateOfBirth: new Date(answers.birth_date.date),
        timeOfBirth: answers.birth_time || null,
        placeOfBirth: answers.birth_place.full_text || null,
        gender: answers.gender,
        zodiac: answers.zodiac,
        relationshipStatus: answers.relationship_status,
      };

      const rStatus = newUserData.relationshipStatus;

      setUserData(newUserData);

      if (!rStatus || rStatus === 'single' || rStatus === 'crush') {
        setInRelationship(false);
        setChartData(quizData!.chart_data.single);
        return;
      }

      setChartData(quizData!.chart_data.relationship);

      const newPartnerData: UserData = {
        dateOfBirth: new Date(answers.partner_birth_date.date),
        timeOfBirth: answers.partner_birth_time || null,
        placeOfBirth: answers.partner_birth_place?.full_text || null,
        gender: answers.partner_gender,
        zodiac: answers.partner_zodiac,
      };

      setPartnerData(newPartnerData);
      setInRelationship(true);
    }
  }, [user, quizData]);

  const resultKeys = quizData
    ? Object.keys(quizData).filter(key => Array.isArray(quizData[key]))
    : [];

  const sectionKey = getQuestionKey(quiz_flow_answers, resultKeys);
  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleContinue = (sectionKey: string) => {
    setLoading({ ...loading, [sectionKey]: true });
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return goToCheckout({ discount: false });
    }
    goToCheckout({ discount: false });
  };

  if (!quizData || !userData) {
    return null;
  }

  const singleOrRelationship = () => {
    if (!userData) {
      return null;
    }

    if (!inRelationship && chartData) {
      return (
        <AstrologicalProfile
          userData={userData}
          chartData={chartData!}
          renderSvgImage={renderSvgImage}
          getZodiacSvgName={getZodiacSvgName}
        />
      );
    }

    if (inRelationship && partnerData) {
      return (
        <CompatibilityProfile
          userData={userData}
          partnerData={partnerData}
          chartData={chartData!}
          renderSvgImage={renderSvgImage}
          getZodiacSvgName={getZodiacSvgName}
          getElement={getElement}
          getEnergy={getEnergy}
        />
      );
    }
  };

  return (
    <Layout
      logoVariant={'left'}
      bgColor="mgBackground"
      hasDivider={false}
      hasShadow={true}
      rightComponent={
        quizData?.navBarBtnTitle ? (
          // <StyledButton onClick={handleContinue}>
          //   {quizData?.navBarBtnTitle}
          // </StyledButton>
          <MgButton onClick={handleContinue}>
            {quizData?.navBarBtnTitle}
          </MgButton>
        ) : (
          <></>
        )
      }
      hideFooter
    >
      {!!quizData?.headlineTitle && (
        <Headline
          bodyText={quizData.headlineTitle}
          bgColor={theme.colors.novaPink}
        />
      )}

      {/* {quizData?.sections?.map(renderSection)} */}
      <CenteringWrapper>
        {singleOrRelationship()}
        <ButtonContainer>
          <ContinueButton onClick={handleContinue}>
            Get my reading
          </ContinueButton>
        </ButtonContainer>
      </CenteringWrapper>
    </Layout>
  );
};

export default Results;
